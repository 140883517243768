.login-gif-animation img{
    width: 400;
    height: 400px;
    margin: auto;
    /* border-radius: 25px;
    box-shadow: 0 19px 38px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 22%); */
}
.wallet-adapter-button{
    margin: auto;
}
.wallet-adapter-button-trigger{
    background-color:#1e4395;
}