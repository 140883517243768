.svg-icon,
.btn-view-project {
    color: #1E4395;
}

.btn-active-milestone,
.btn-view-project {
    background-color: #1e43951a;
}

.btn-active-milestone:hover,
.btn-view-project:hover {
    background-color: #1e43959c;
    color: #fff;
}

.btn-active-milestone:hover .svg-icon {
    color: #fff;
}

.btn-view-all {
    background-color: #a1a5b7;
    color: #fff;
}

.btn-view-all:hover {
    background-color: #1e43959c;
    color: #fff;
}

.total-project-card {
    background: transparent linear-gradient( 231deg, #2898D5 0%, #1D4496 100%) 0% 0% no-repeat padding-box;
}

.bg-gray-100 {
    background-color: #f3f6f9;
}

.text-blue {
    color: #121212;
}

.symbol.symbol-30px {
    width: 100%;
}

.symbol.symbol-30px>img {
    float: right;
}

.symbol.symbol-45px>img {
    width: 35px;
    height: 35px;
}