.profile {
    color: #1e4395;
    font-size: 13px;
    font-weight: 600;
}

.btn-profile-update {
    background-color: #181c32;
    color: #fff;
}

.btn-profile-update:hover {
    background-color: #3f4254;
    color: #fff;
}

.btn-profile-update:focus:not(.btn-active) {
    background-color: #181c32;
    color: #fff;
}

.menu-sub.menu-sub-dropdown {
    z-index: 105;
    position: fixed;
    display: flex;
    inset: 0px 0px auto auto;
    margin: 0px;
    transform: translate3d(-30px, 80px, 0px);
}

.display-flex {
    display: flex;
}

.news {
    /* background-color: #3f4254;
    border-radius: 9999px; */
    padding: 7px;
}

.news-heading {
    background-color: #f1bc00;
    border-radius: 9999px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    font-weight: 600;
}

.news-sub-heading {
    color: #3f4254 !important;
}

.wallet-adapter-button-inside {
    height: 40px !important;
    color: #1e4395 !important;
    border-color: #1e4395 !important;
    background-color: #1e439514 !important;
}

.wallet-adapter-button-insid:hover {
    background-color: unset !important;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: unset;
    color: unset;
}

#basic-nav-dropdown:hover {
    color: #1e4395 !important;
}

.dropdown-item {
    margin: 0px;
    font-size: 14px;
    color: #1e4395;
    font-weight: 500;
    text-align: center;
}

.profile-name {
    font-size: 14px;
    font-weight: 600;
}

.dropdown-item:focus,
.dropdown-item:hover {
    background-color: unset;
}

.header-fixed .header {
    top: 35px;
}

div.psst {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 999;
    padding: 10px 50px 0px 50px;
    background: #1e4395;
    color: #fff;
    font-size: 15px;
    text-align: center;
    line-height: 1.3;
}

.psst a {
    color: #ffc107;
    font-weight: 400;
}


/* .notifications {
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    color: black;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  
  .notification {
    padding: 5px;
    font-size: 14px;
  } */

.notification-menu {
    z-index: 105;
    position: fixed;
    inset: 0px 0px auto auto;
    margin: 0px;
    transform: translate3d(110px, 0px, 0px) !important;
}

#notification-dropdown::after {
    display: none;
}

.icon-button__badge {
    position: absolute;
    top: 10px;
    right: 20px;
    width: 25px;
    height: 25px;
    background: #1e4395;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.Toastify__toast-container--top-right {
    top: 9em !important;
}

.Toastify__toast-theme--light {
    background-color: #000;
    color: #fff;
}