.project-firstname-container {
    border: 1px solid #181c32;
    padding: 25px;
    background: #181c32;
    border-radius: 9px;
}

.project-firstname {
    font-size: 70px;
    font-weight: 600;
    color: #fff;
    text-transform: capitalize;
}

.ms-4 {
    margin-left: 0% !important;
}

.btn-create-dispute {
    background: #f5f8fa;
    color: #1e4395 !important;
    width: 140px;
    padding: 10px !important;
}

.btn-back-dispute {
    background: #f5f8fa;
    color: #1e4395 !important;
    width: 200px;
    padding: 10px !important;
}

.plans {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px 0px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.plans .plan input[type="radio"] {
    position: absolute;
    opacity: 0;
}

.plans .plan {
    cursor: pointer;
    width: 32%;
    margin-right: 10px;
}

.plans .plan .plan-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 15px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 2px solid #e1e2e7;
    border-radius: 10px;
    -webkit-transition: -webkit-box-shadow 0.4s;
    transition: -webkit-box-shadow 0.4s;
    -o-transition: box-shadow 0.4s;
    transition: box-shadow 0.4s;
    transition: box-shadow 0.4s, -webkit-box-shadow 0.4s;
    position: relative;
    min-height: 85px;
    max-height: 85px;
}

.plans .plan .plan-content img {
    margin-right: 6px;
    height: 45px;
}

.plans .plan .plan-details span {
    margin-bottom: 10px;
    display: block;
    font-size: 15px;
    line-height: 20px;
    color: #252f42;
}

.container .title {
    font-size: 16px;
    font-weight: 500;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    color: #252f42;
    margin-bottom: 20px;
}

.plans .plan .plan-details p {
    color: #646a79;
    font-size: 14px;
    line-height: 18px;
}

.plans .plan .plan-content:hover {
    -webkit-box-shadow: 0px 3px 5px 0px #e8e8e8;
    box-shadow: 0px 3px 5px 0px #e8e8e8;
}

.plans .plan input[type="radio"]:checked+.plan-content:after {
    content: "";
    position: absolute;
    height: 8px;
    width: 8px;
    background: #216fe0;
    right: 20px;
    top: 20px;
    border-radius: 100%;
    border: 3px solid #fff;
    -webkit-box-shadow: 0px 0px 0px 2px #0066ff;
    box-shadow: 0px 0px 0px 2px #0066ff;
}

.plans .plan input[type="radio"]:checked+.plan-content {
    border: 2px solid #216ee0;
    background: #eaf1fe;
    -webkit-transition: ease-in 0.3s;
    -o-transition: ease-in 0.3s;
    transition: ease-in 0.3s;
}

@media screen and (max-width: 991px) {
    .plans {
        margin: 0 20px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        padding: 40px;
    }
    .plans .plan {
        width: 100%;
    }
    .plan.complete-plan {
        margin-top: 20px;
    }
    .plans .plan .plan-content .plan-details {
        width: 70%;
        display: inline-block;
    }
    .plans .plan input[type="radio"]:checked+.plan-content:after {
        top: 45%;
        -webkit-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%);
    }
}

@media screen and (max-width: 767px) {
    .plans .plan .plan-content .plan-details {
        width: 60%;
        display: inline-block;
    }
}

@media screen and (max-width: 540px) {
    .plans .plan .plan-content img {
        margin-bottom: 20px;
        height: 56px;
        -webkit-transition: height 0.4s;
        -o-transition: height 0.4s;
        transition: height 0.4s;
    }
    .plans .plan input[type="radio"]:checked+.plan-content:after {
        top: 20px;
        right: 10px;
    }
    .plans .plan .plan-content .plan-details {
        width: 100%;
    }
    .plans .plan .plan-content {
        padding: 20px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
    }
}

.dispute-upload-btn {
    background-color: #1e43951a;
    color: #1E4395;
}

.dropzone {
    border: 1px dashed #1e43952e;
    background-color: unset;
    padding: 0.5rem 0.75rem;
}


/* .btn-dispute-submit {} */

.btn-dispute-cancel {
    background-color: #1e43951a;
    color: #1E4395;
}

.dispute-sub-heading {
    font-size: 16px;
    font-weight: 500;
    color: #1e4395;
}

.dispute-status-card {
    background: #F5F8FA;
}

.modal-r90w {
    width: 60vw;
    max-width: 70vw;
}