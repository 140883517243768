button#tab-chat-1-tab-inbox.active {
    border: unset;
    border-bottom: 2px solid #186AB7;
    /* border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 5px 10px; */
}

.clr-blue {
    background-color: #3498db !important;
    margin-left: 7px;
}

button#tab-chat-1-tab-inbox,
button#tab-chat-1-tab-message-request,
button#tab-chat-1-tab-send-message-request {
    color: #186AB7;
    font-size: 1.1em;
    font-weight: 600;
}

button#tab-chat-1-tab-inbox:focus-visible,
button#tab-chat-1-tab-message-request:focus-visible,
button#tab-chat-1-tab-send-message-request:focus-visible {
    border: unset;
}

button#tab-chat-1-tab-message-request.active {
    border: unset;
    border-bottom: 2px solid #186AB7;
    /* border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 5px 10px; */
}

.clr-green {
    background-color: #2ecc71 !important;
    margin-left: 7px;
}

button#tab-chat-1-tab-send-message-request.active {
    border: unset;
    border-bottom: 2px solid #186AB7;
    /* border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 5px 10px; */
}

.clr-red {
    background-color: #e74c3c !important;
    margin-left: 7px;
}

button#tab-chat-1-1-tab-received,
button#tab-chat-1-1-tab-sent {
    color: #186AB7;
    font-weight: 500;
    font-size: 1em;
}

button#tab-chat-1-1-tab-received.active {
    border: unset;
    border-bottom: 2px solid #186AB7;
    /* border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 5px 10px; */
}

.clr-yellow {
    background-color: #f1c40f !important;
    margin-left: 7px;
}

button#tab-chat-1-1-tab-sent.active {
    border: unset;
    border-bottom: 2px solid #186AB7;
    /* border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 5px 10px; */
}

.clr-orange {
    background-color: #d35400 !important;
    margin-left: 7px;
}

#kt_chat_contacts_header {
    border-bottom: none !important;
}

.cls_chat_list {
    cursor: pointer;
    border-bottom: 1px solid #f3f1f1;
}

.cls-chat-preview-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 212px;
}

.cls-chat-height {
    height: 46vh !important;
    max-height: 46vh !important;
}


/* .tab-content{
  height: 70vh !important;
  max-height: 70vh !important;
} */

.cls-dv-chat-right {
    background: white;
    border-radius: 5px;
}

#kt_chat_messenger {
    width: 100%;
}

#kt_chat_messenger_header {
    padding: 7px 7px 0 10px !important;
    min-height: 0 !important;
}

.user-online {
    color: #186AB7;
}

.btn-chat-accept-req {
    background-color: #2ecc71;
    color: #fff;
}

.btn-chat-reject-req {
    background-color: #e74c3c;
    color: #fff;
}

.btn-chat-reject-req:hover,
.btn-chat-accept-req:hover {
    color: #fff;
}