.wallet-vaild-icon, .balance-vaild-icon{
    color: #198754 !important;
    font-size: 25px !important;
}


.wallet-invaild-icon, .low-balance-iocn{
    color: #dc3545 !important;
    font-size: 25px !important;
}

.modal-90w{
    width: 90vw ;   
    max-width: 90vw;
}