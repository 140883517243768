.project-tab a{
    width: inherit !important;
    padding: 22px !important;
    font-size: 15px;
    font-weight: 600;
}
.btn-view-project{
    background-color: #1e43951a;
}
.btn-view-project:hover{
    background-color: #1e43959c;
    color: #fff;
}