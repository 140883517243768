.menu-item {
    margin: auto;
    text-align: center;
}

.menu-item a {
    color: inherit;
}
.aside-menu-title:hover, .aside-menu-title .menu-icon:hover{
    color: #fff;
}


.menu-item a:hover i {
    color: inherit;
}

.menu-state-icon-primary .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) .menu-icon i {
    color: #fff;
}

#kt_modal_new_contract label {
    color: #5343a4;
}

.aside-menu-title {
    color: #ffffffad;
    font-size: 12px;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100px;
    height: 100px;
    border: 10px solid #f3f3f3;
    /* Light grey */
    border-top: 10px solid #383636;
    /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}
.spinner-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #181c32a3;
    z-index: 99999;
}
.swal2-popup .swal2-title{
    font-size: 2rem;
}

.menu-item.show .aside-menu-title,.menu-item.show .aside-menu-title .menu-link .menu-icon i{
    color: #fff;
}
 .btn-view-project{
    background-color: #1e43951a;
}
.btn-view-project:hover{
    background-color: #1e43959c;
    color: #fff;
}